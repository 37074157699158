.around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  :global {
    .ant-form-item {
      display: inline-flex;
      width: 250px;
    }
    .ant-form-item-label {
      > label {
        color: var(--JordyBlue);
      }
    }
    .ant-input,
    .ant-input:focus,
    .ant-select-selector {
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      padding-left: 0 !important;
    }
    .ant-select-selection-search {
      left: 0 !important;
    }
    .ant-form-item-extra {
      margin-top: 8px;
      font-family: HiraginoSansGB-W3;
      font-size: 10px;
      line-height: 1;
      color: var(--Lipstick);
      width: 272px;
    }
  }
}
:global {
  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}
.center {
  :global {
    .ant-form-item {
      margin-bottom: 30px;
    }
    .ant-form-item-control {
      max-width: 232px;
      .ant-input,
      .ant-input-password {
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
        .ant-input {
          padding-left: 8px;
        }
        min-height: 32px;
      }
      .ant-input-password {
        padding-left: 0;
      }
    }
    .ant-form-item-label {
      > label {
        color: var(--DustyGray9);
        font-family: HiraginoSansGB-W6;
        font-size: 12px;
      }
    }
    .ant-form-item-extra {
      margin-top: 8px;
      font-family: HiraginoSansGB-W3;
      font-size: 10px;
      line-height: 1;
      color: var(--Lipstick);
      width: 232px;
    }
  }
}

@primary-color: #b7015b;@border-radius-base: 4px;@link-color: #2c7fe3;