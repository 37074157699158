@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  scroll-behavior: smooth;
}

body,
#root {
  width: 100%;
  height: 100vh;
}

:root {
  --Lipstick: #b7015b;
  --DoveGray: #666666;
  --Monza: #d0011b;
  --Boulder5: #757575;
  --RoyalBlue: #2c7fe3;
  --Turquoise: #4ad0c6;
  --Silver4: #c4c4c4;
  --Concrete: #f2f2f2;
  --VanillaIceF: #efd4e2;
  --Mercury: #e4e4e4;
  --RedDevil: #8a0011;
  --MineShaft1: #1f1f1f;
  --TyrianPurple: #680033;
  --JordyBlue: #95bff1;
  --AltoA: #dadada;
  --DustyGray9: #999999;
  --DustyGray7: #979797;
  --SilverChaliceA: #aaaaaa;
  --Boulder7: #777777;
  --Alto4: #d4d4d4;
  --White: #ffffff;
  --SilverChalice1: #a1a1a1;
  --Black0: #000000;
  --Amber: #ffbe00;
  --Alabaster: #fbfbfb;
  --Woodsmoke: #141519;
  --CodGray7: #070707;
  --Jumbo: #727275;
  --WildSand: #f5f5f5;
  --MineShaft3: #333333;
  --WildWatermelon: #ff6a72;
  --CodGrayD: #0d0b0a;
  --SilverD: #cdcdcd;
  --Alto8: #d8d8d8;
  --Alto7: #d7d7d7;
  --Mischka: #d9d9dd;
  --GalleryA: #eaeaea;
  --Gray: #898989;
  --SilverChaliceC: #acacac;
  --Chicago: #5d5954;
  --Taupe: #40382c;
  --Tundora: #484848;
  --SilverF: #bfbfbf;
  --Emperor: #505050;
  --VanillaIce1: #f1ccde;
  --VanillaIce4: #f4d9e7;
  --Black3: #030303;
  --Black1: #010101;
  --Boulder4: #747474;
  --CocoaBrown: #231916;
  --Amour: #f7e5ee;
  --Prim: #ebd9e2;
  --GalleryE: #eeeeee;
  --Gallery0: #f0f0f0;
  --FreshEggplant: #9b004d;
  --RangoonGreen: #0b0a08;
  --Pampas: #f8f7f4;
  --Westar: #dfd9d0;
  --VanillaIce0: #f0d5e3;

  --SiderMenuWidth: 200px;
}

.ant-divider {
  border-color: var(--Alto8);
}

.ant-btn-ghost {
  font-family: HiraginoSansGB-W6;
  font-size: 12px;
  text-align: center;
  color: var(--Lipstick);
  border-color: var(--Lipstick);
  border-radius: 2px;
}

.cursor-default {
  cursor: default !important;
}

@primary-color: #b7015b;@border-radius-base: 4px;@link-color: #2c7fe3;