.form {
  :global {
    .ant-form-item-explain {
      visibility: hidden;
    }
  }
}

.submitButton {
  width: 115px;
  height: 32px;
  border-radius: 2px;
  background-color: var(--Lipstick);
  font-family: HiraginoSansGB-W3;
  font-size: 14px;
  color: var(--White);
}

@primary-color: #b7015b;@border-radius-base: 4px;@link-color: #2c7fe3;