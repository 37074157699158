.title {
  font-family: HiraginoSansGB-W6;
  font-size: 14px;
  color: var(--Chicago);
  position: relative;
  margin-bottom: 20px;
  &.withPrefix {
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -15px;
      width: 5px;
      height: 12px;
      background-color: var(--Lipstick);
    }
  }
}

.readOnly {
  cursor: default;
  &:global(.ant-picker) {
    pointer-events: none;
  }
  :global {
    .ant-select-selector,
    .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      pointer-events: none;
    }
  }
}
.uploads {
  :global {
    .ant-form-item-control-input-content {
      display: flex;
    }
    .ant-upload-picture-card-wrapper {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.cardLimit {
  :global {
    .ant-form-item-control-input-content {
      display: inline-flex;
      justify-content: space-between;
    }
    .ant-picker {
      height: 32px;
      width: 100px;
      border-radius: 0;
    }
  }
}
.noPickerHeader {
  :global {
    .ant-picker-header {
      display: none;
    }
  }
}

.termsOfPurchase {
  width: 100% !important;
  .card {
    flex: 1;
    border-bottom: solid 1px var(--WildSand);
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      min-width: 165px;
    }
    &:nth-child(2) {
      min-width: 250px;
      :global {
        .ant-card-body {
          max-width: 245px;
        }
      }
    }
    &:nth-child(3) {
      min-width: 165px;
    }
    &:nth-child(4) {
      min-width: 195px;
    }
    &:nth-child(5) {
      min-width: 100px;
    }
    :global {
      .ant-card-head {
        display: flex;
        align-items: center;
        background-color: var(--Lipstick);
        border-radius: 0;
        font-family: HiraginoSansGB-W6;
        font-size: 12px;
        color: var(--White);
        padding-left: 15px;
        padding-right: 0;
      }
      .ant-card-body {
        flex-grow: 1;
        padding: 0;
        padding-left: 15px;
        min-height: 50px;
        display: flex;
        align-items: center;
        font-family: HiraginoSansGB-W3;
        font-size: 10px;
        line-height: 1;
        color: var(--MineShaft3);
      }
      .ant-card-head-title {
        padding: 0;
      }
    }
  }
  :global {
    .ant-form-item-control-input-content {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &.isMobile {
    :global {
      .ant-form-item-control-input-content {
        flex-direction: column;
      }
    }
  }
}
.customSelect {
  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  {
      height: auto;
    }
    .ant-select-selection-item  {
      overflow: visible;
      white-space: initial;
      text-overflow: clip;
    }
  }
}

@primary-color: #b7015b;@border-radius-base: 4px;@link-color: #2c7fe3;